import * as React from "react"
import styled from "styled-components"
import { breakPoint } from "../../src/shared/consts"
import "./fonts.css"

const Wrap = styled.div`
  text-align: center;
  /* margin: 130px 0; */
`
const Text = styled.p`
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  padding: 40px 0;
  text-align: center;
  font-style: italic;
  strong {
    color: rgb(233, 72, 77);
  }
  background: transparant;
  @media (max-width: ${breakPoint}px) {
    font-size: 18px;
  }
`

const QuoteImg = styled.img`
  width: 15px;
`

const QuoteL =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTMgMTQuNzI1YzAtNS4xNDEgMy44OTItMTAuNTE5IDEwLTExLjcyNWwuOTg0IDIuMTI2Yy0yLjIxNS44MzUtNC4xNjMgMy43NDItNC4zOCA1Ljc0NiAyLjQ5MS4zOTIgNC4zOTYgMi41NDcgNC4zOTYgNS4xNDkgMCAzLjE4Mi0yLjU4NCA0Ljk3OS01LjE5OSA0Ljk3OS0zLjAxNSAwLTUuODAxLTIuMzA1LTUuODAxLTYuMjc1em0tMTMgMGMwLTUuMTQxIDMuODkyLTEwLjUxOSAxMC0xMS43MjVsLjk4NCAyLjEyNmMtMi4yMTUuODM1LTQuMTYzIDMuNzQyLTQuMzggNS43NDYgMi40OTEuMzkyIDQuMzk2IDIuNTQ3IDQuMzk2IDUuMTQ5IDAgMy4xODItMi41ODQgNC45NzktNS4xOTkgNC45NzktMy4wMTUgMC01LjgwMS0yLjMwNS01LjgwMS02LjI3NXoiLz48L3N2Zz4="

const QuoteR =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTEgOS4yNzVjMCA1LjE0MS0zLjg5MiAxMC41MTktMTAgMTEuNzI1bC0uOTg0LTIuMTI2YzIuMjE1LS44MzUgNC4xNjMtMy43NDIgNC4zOC01Ljc0Ni0yLjQ5MS0uMzkyLTQuMzk2LTIuNTQ3LTQuMzk2LTUuMTQ5IDAtMy4xODIgMi41ODQtNC45NzkgNS4xOTktNC45NzkgMy4wMTUgMCA1LjgwMSAyLjMwNSA1LjgwMSA2LjI3NXptMTMgMGMwIDUuMTQxLTMuODkyIDEwLjUxOS0xMCAxMS43MjVsLS45ODQtMi4xMjZjMi4yMTUtLjgzNSA0LjE2My0zLjc0MiA0LjM4LTUuNzQ2LTIuNDkxLS4zOTItNC4zOTYtMi41NDctNC4zOTYtNS4xNDkgMC0zLjE4MiAyLjU4NC00Ljk3OSA1LjE5OS00Ljk3OSAzLjAxNSAwIDUuODAxIDIuMzA1IDUuODAxIDYuMjc1eiIvPjwvc3ZnPg=="

export function Quote({ text, mobileSize, desktopSize, breakPoint, ...props }) {
  return (
    <Wrap>
      <QuoteImg src={QuoteL} />
      <Text dangerouslySetInnerHTML={{ __html: text }} />
      <QuoteImg src={QuoteR} />
    </Wrap>
  )
}

Quote.defaultProps = {
  text: "Type Text",
  breakPoint: 700,
}
