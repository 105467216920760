import * as React from "react"
import styled from "styled-components"
import * as System from "../../../design-system"

export function Heading2Default({ ...props }) {
  return (
    <>
      <System.Spacing height={80} />
      <System.Heading2 {...props} />
      <System.Spacing height={1} />
    </>
  )
}

Heading2Default.defaultProps = {
  h2: "Type Text",
}
