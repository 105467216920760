import * as React from "react"
import styled from "styled-components"
import { breakPoint } from "../../src/shared/consts"
import "./fonts.css"

const H1 = styled.h1`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -1.2px;
  text-align: center;
  @media (max-width: ${breakPoint}px) {
    font-size: 24px;
  }
  background: transparent;
`

export function HighlightImageText({ text, ...props }) {
  return <H1 dangerouslySetInnerHTML={{ __html: text }} />
}

HighlightImageText.defaultProps = {
  text: "Type Text",
}
