import * as React from "react"
import styled from "styled-components"
import { breakPoint } from "../../src/shared/consts"
import "./fonts.css"

const H2 = styled.h2`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  /* margin-top: 80px; */
  /* margin-bottom: 1px; */
  strong {
    font-family: "NanumSquare";
    font-weight: 800;
    color: rgb(233, 72, 77);
  }
  strike {
    font-style: italic;
  }
  @media (max-width: ${breakPoint}px) {
    font-size: 20px;
  }
  background: transparant;
  span {
    font-size: 14px;
    opacity: 0.7;
  }
`

export function Heading2({
  h2,
  size,
  mobileSize,
  tabletSize,
  desktopSize,
  breakPoint,
  color,
  ...props
}) {
  return <H2 dangerouslySetInnerHTML={{ __html: h2 }} />
}

Heading2.defaultProps = {
  h2: "Type Text",

  breakPoint: 700,
  color: "black",
}
