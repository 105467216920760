import * as React from "react"
import styled from "styled-components"
import { breakPoint } from "../../src/shared/consts"

const Wrap = styled.div`
  @media (max-width: ${breakPoint}px) {
    overflow: hidden;
    width: 100%;
  }
`

export function FbLike() {
  return (
    <Wrap>
      <div
        className="fb-like"
        data-href="https://www.facebook.com/harbor.sch/"
        data-width=""
        data-layout="standard"
        data-action="like"
        data-size="small"
        data-show-faces="true"
        data-share="false"
      />
    </Wrap>
  )
}

FbLike.defaultProps = {
  width: 300,
  height: 60,
}
