import * as React from "react"
import styled, { css } from "styled-components"
import Countdown from "react-countdown-now"
import { motion, AnimatePresence } from "framer-motion"
import { useState } from "react"

const Wrap = styled(motion.div)``
const BlurWrap = styled(motion.div)`
  /* Add one more blur div for IOS issue */
  backdrop-filter: blur(4px);
`
const Content = styled(motion.div)`
  position: relative;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  padding: 20px 25px;
  background: rgba(42, 73, 161, 0.9);
  border-radius: 6px;
  backdrop-filter: blur(4px);

  /* Text Styles */
  font-family: NanumSquare;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      @media (max-width: 1080px) {
        display: none;
      }
    `}
`
const Title = styled.div``
const Em = styled.div`
  font-weight: 800;
  margin-top: 20px;
`
const Time = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
`

const wrap = {
  active: {
    opacity: 1,
    x: "0%",
  },
  inActive: {
    opacity: 0,
    x: "100%",
  },
}

let isDragging = false
export function Notification({
  id,
  title,
  em,
  time,
  handleOnClick,
  hideOnMobile,
}) {
  const [active, setActive] = useState(true)
  return (
    <AnimatePresence>
      {active && (
        <Wrap // Close Interaction
          variants={wrap}
          animate={active ? "active" : "inActive"}
          layoutId={id} // for AnimateSharedLayout
          onDoubleClick={() => {
            !isDragging && handleOnClick && handleOnClick()
            setActive(false)
          }}
          drag="x"
          dragConstraints={{
            left: 0,
            right: 0,
          }}
          dragElastic={1}
          onDrag={() => (isDragging = true)}
          onDragEnd={(e, info) => {
            isDragging = false
            var dragAmountX = info.point.x
            if (dragAmountX > 140) setActive(false)
          }}
        >
          {/* AnimatePresence Animation */}
          <BlurWrap
            initial={wrap.inActive}
            animate={wrap.active}
            exit={wrap.inActive}
            transition={{
              delay: id * 0.28,
              duration: 0.36,
            }}
          >
            <Content hideOnMobile={hideOnMobile}>
              <Title>{title}</Title>
              <Em>{em}</Em>
              <Time>
                남은시간 <Countdown date={time} />
              </Time>
            </Content>
          </BlurWrap>
        </Wrap>
      )}
    </AnimatePresence>
  )
}

Notification.defaultProps = {
  show: true,
}
