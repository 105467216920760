import PropTypes from "prop-types"
import React from "react"
import { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Checkout } from "./Checkout"
import { ClassOption } from "./ClassOption"
import { TimeLeft } from "./TimeLeft"
import { Frame, Stack } from "framer"
import useStore from "../../datas/useStoreFkm"
import Countdown from "react-countdown-now"
import {
  CLASS_OPTIONS,
  CURRENT_PERIOD,
  CURRENT_NUMBER_OF_THE_PRODUCTS,
  deadLineSuperEarlyBird,
  deadLineEarlyBird,
  deadLineFinal,
  hfOpened,
} from "../../shared/classConsts/framer-korea-meetup"
import { preventScrollingBehindFixedOverlay } from "../../shared/utils"
import PurchaseSection from "./PurchaseSection"

var Scroll30 = false
var Scroll50 = false
var Scroll60 = false
var Scroll90 = false

const FixedWrap = styled(Frame)`
  position: fixed !important;
  bottom: 0;
  left: 0;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background: #f5f7fa !important;
  pointer-events: all;
  /* enable smooth scroll */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -5px 15px 0px;
  ${({ opened }) =>
    opened &&
    css`
      overflow-y: scroll !important;
    `}
`
const Button = styled.div`
  background: #fdd388;
  cursor: pointer;
  text-align: center;
  height: 80px;
  padding: 10px 0;
  position: relative;
`
const Notice = styled(Frame)`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  color: #e9484d;
`
const BuyText = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 28px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  span {
    font-family: "NanumSquare";
    font-weight: 700;
    font-size: 18px;
    vertical-align: middle;
  }
  ${props =>
    props.hfOpened === false &&
    css`
      opacity: 0.3;
    `}
`

const ClassOptions = styled(Frame)`
  width: 375px !important;
  height: 375px !important;
  border-radius: 6px;
`

const Wrap = styled(Stack)`
  width: 335px !important;
  height: 291px !important;
`

const Text1 = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  text-align: left;
`
const Text2 = styled.div`
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 16px;
  text-align: right;
`
const ClassTypeText = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  text-align: right;
`
var temp = 0
var delta = 0
var initiateCheckout = false
var initialHeight,
  urlBarAppeared = true

const PurchaseArea = ({ ...props }) => {
  const [opened, setOpened] = useState(false)
  const [show, setShow] = useState(true)
  const [scrollAmount, setScrollAmount] = useState(0)
  // const [urlBarAppeared, setUrlBarAppeared] = useState(true)
  const [classOption, setClassOption] = useStore()

  useEffect(() => {
    initialHeight = window.innerHeight

    window.addEventListener("scroll", e => {
      e.preventDefault()
      // return false
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )

      // console.log(scrollTop)
      delta = scrollTop - temp
      temp = scrollTop

      if (delta > 0 && urlBarAppeared)
        // scroll down
        setShow(false)
      // scroll up
      else if (delta < 0) setShow(true)
    })

    //attach event touchend
    window.addEventListener("resize", e => {
      var w = e.target
      urlBarAppeared = false
      console.log("URL bar Disappeared!!")
      var newHeight = w.innerHeight
      if (newHeight <= initialHeight) {
        console.log("URL bar APPEARED!!")
        setTimeout(() => {
          urlBarAppeared = true
        }, 1000)
      }
    })
  }, [])

  return (
    <FixedWrap
      width="100%"
      initial={{
        height: 80,
      }}
      animate={{
        height: opened ? "80vh" : 80,
        bottom: show ? 0 : -80,
      }}
      transition={{
        ease: "easeInOut",
      }}
      onTap={() => {}} // To enable pointer-events (scroll)
      id="purchase-area"
      onScroll={e => setScrollAmount(e.target.scrollTop)}
      opened={opened}
    >
      <Button
        onMouseDown={() => {
          if (hfOpened) {
            // resolve scroll issue
            preventScrollingBehindFixedOverlay("purchase-area")
            if (!opened) {
              // open!
              document.body.style.overflow = "hidden"
              setOpened(true)
              // track InitiateCheckout (fb pixel)
              if (
                typeof window !== "undefined" &&
                window.fbq != null &&
                !initiateCheckout
              ) {
                // window.fbq("track", "InitiateCheckout", {
                //   content_type: "framer-korea-meetup",
                // })
                initiateCheckout = true
                console.log("initiateCheckout!")
              }
            } else {
              // close!
              document.body.style.overflow = "initial"
              setOpened(false)
            }
          } else {
            // Alert for Notification for next class
            if (
              typeof window !== "undefined" &&
              window.confirm(
                "죄송합니다. 현재 해당 티켓이 모두 품절 되었습니다. \n\n대기명단을 등록하시려면\n다음 폼을 작성해주세요\n\n확인 버튼을 누르시면 대기명단 등록 폼으로 이동합니다."
              )
            )
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLScgckjjKZ4ev9hQsWMA6glV6pV7LOmPYpkJplMkqAsUxWPxnw/viewform?usp=sf_link"
              )
          }
        }}
      >
        <Notice
          width="100%"
          height=""
          background=""
          initial={{
            left: -200,
            opacity: 0,
          }}
          animate={{
            left: 0,
            opacity: 1,
          }}
          transition={{
            delay: 1,
          }}
        >
          {CURRENT_NUMBER_OF_THE_PRODUCTS.description}
        </Notice>
        <BuyText hfOpened={hfOpened}>
          {opened ? "닫기" : "티켓 구입"}{" "}
          <span>
            (
            <Countdown
              date={(() => {
                switch (CURRENT_PERIOD.description) {
                  case "Framer Korea 밋업 기념 특가":
                    return deadLineSuperEarlyBird
                  case "얼리버드":
                    return deadLineEarlyBird
                  default:
                    return deadLineFinal
                    break
                }
              })()}
            />
            초 남음)
          </span>
        </BuyText>
      </Button>
      <PurchaseSection scrollAmount={scrollAmount} />
    </FixedWrap>
  )
}

export default PurchaseArea
