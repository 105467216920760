import * as React from "react"
import styled from "styled-components"
import * as System from "../../../design-system"

const Wrap = styled.div``
const Img = styled.img`
  width: 100%;
  height: 100%;
`

export function Image({ renderedImg, image, caption, height, ...props }) {
  return (
    <Wrap>
      {renderedImg ? renderedImg : <Img src={image} />}
      <System.Caption caption={caption} />
    </Wrap>
  )
}

Image.defaultProps = {
  caption: "",
}
