import * as React from "react"
import styled from "styled-components"
import { breakPoint } from "../../src/shared/consts"

const Wrap = styled.div`
  height: 100%;
  @media (max-width: ${breakPoint}px) {
    height: calc((100vw - 48px) * 0.5625);
  }
`

export function Video({
  text,
  mobileSize,
  desktopSize,
  breakPoint,
  youtubeId,
  ...props
}) {
  return (
    <Wrap>
      <iframe
        src={"//www.youtube.com/embed/" + youtubeId + "?autoplay=1&mute=1"}
        style={{
          width: "100%",
          height: "100%",
        }}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </Wrap>
  )
}

Video.defaultProps = {
  youtubeId: "i42t2LeXLe8",
}
