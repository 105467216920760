import axios from "axios"
import {
  IAMPORT_BASE_URL,
  SES_BASE_URL,
  noitifyEmail,
  fromEmail,
  imp_uid,
} from "../shared/consts"
import { isClient } from "../shared/utils"
import { now, NEXT_PAYMENT } from "../shared/classConsts/hello-framer-x"
import sendMail from "./sendMail"
import { createSubscription } from "./createSubscription"

const log = process.env.PRODUCTION === "true" ? () => void 0 : console.log

const handler = async (
  orderData,
  total,
  isSubscription,
  setOrderState,
  afterSuccess
) => {
  log("orderData", orderData)
  log("total", total)
  const nowStr = "" + now
  isClient && window.IMP.init(imp_uid)
  // get Token here
  const tokenResponse = await axios.post(IAMPORT_BASE_URL + "/getAccessToken")
  const access_token = tokenResponse.data.response.access_token
  // const customer_uid = orderData.email + "_" + orderData.cardNumber.slice(-4)
  const merchant_uid =
    nowStr.substr(nowStr.length - 5) +
    " [" +
    orderData.type +
    "][" +
    (() => {
      let res = ""
      if (orderData.inflowFbPage) res += "post, "
      if (orderData.inflowFbAd) res += "fb-ad, "
      if (orderData.inflowInstaAd) res += "ins-ad, "
      if (orderData.inflowNewsletter) res += "news, "
      if (orderData.inflowKakao) res += "kakao, "
      if (orderData.inflowFriend) res += "fr, "
      if (orderData.inflowEtc) res += "etc, "
      return res.slice(0, -2)
    })() +
    "]"

  isClient &&
    window.IMP.request_pay(
      {
        pg: "nice",
        pay_method: "card",
        merchant_uid: merchant_uid,
        amount: total,
        name: "Hello, Framer X",
        buyer_name: orderData.name,
        buyer_tel: orderData.phone,
        buyer_email: orderData.email,
        m_redirect_url: window.location.origin + "/order-complete/",
      },
      async res => {
        console.log("res", res)
        if (!res.success) {
          alert("결제에러 - " + res.error_msg)
          setOrderState(0)
        } else {
          // if (isSubscription) {
          //   // add scheduled orders
          //   createSubscription(
          //     access_token,
          //     res.imp_uid,
          //     merchant_uid,
          //     orderData,
          //     total,
          //     setOrderState,
          //     subscriptionorderResponse => {
          //       alert("Hello, Framer X\n\n주문이 완료 되었습니다 감사합니다!")
          //       log("subscriptionorderResponse", subscriptionorderResponse)
          //       afterSuccess({
          //         schedule_at:
          //           subscriptionorderResponse.response[0].schedule_at,
          //         // ...orderResponse.data.response,
          //         ...res,
          //         // .data.response,
          //       })
          //       setOrderState(5)
          //     }
          //   )
          // } else {
          await sendMail(
            fromEmail,
            [noitifyEmail, orderData.email],
            orderData.name,
            orderData.type
          )
          alert("주문이 완료 되었습니다 감사합니다!")
          afterSuccess({
            schedule_at: "",
            manual: true,
            amount: res.paid_amount,
            ...res,
          })
          setOrderState(5)
          // }
        }
      }
    )
}

export default handler
