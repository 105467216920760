import * as React from "react"
import * as System from "../../../design-system"

export function QuoteDefault({ ...props }) {
  return (
    <>
      <System.Spacing height={140} />
      <System.Quote {...props} />
      <System.Spacing height={140} />
    </>
  )
}

QuoteDefault.defaultProps = {
  text: "Quote Text",
}
