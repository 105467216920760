import * as React from "react"
import * as System from "../../../design-system"

export function PageTitleDefault({ ...props }) {
  return (
    <>
      <System.Spacing height={140} />
      <System.PageTitle {...props} />
    </>
  )
}

PageTitleDefault.defaultProps = {
  h1: "Type Text",
}
