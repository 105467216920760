import * as React from "react"
import styled from "styled-components"
import { MenuEnter } from "./MenuEnter"
import { LogoKo } from "./LogoKo"

const LinkWrap = styled.h1`
  margin: 0;
  position: relative;
`

const CenteredLink = styled.a`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const Header = ({ siteTitle, onClick }) => (
  <header
    style={{
      background: `#2348A7`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1080,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <LinkWrap>
        <a
          style={{
            textDecoration: `none`,
          }}
          onClick={onClick}
        >
          <LogoKo />
        </a>

        <CenteredLink
          href="https://link.harbor.school/class-header"
          target="_blank"
        >
          <MenuEnter />
        </CenteredLink>
      </LinkWrap>
    </div>
  </header>
)

Header.defaultProps = {
  siteTitle: ``,
}
