import axios from "axios"
import {
  IAMPORT_BASE_URL,
  SES_BASE_URL,
  noitifyEmail,
  fromEmail,
} from "../shared/consts"
import { now } from "../shared/classConsts/hello-framer-x"
import { NEXT_PAYMENT } from "../shared/classConsts/hello-framer-x"
import sendMail from "./sendMail"
import { createSubscription } from "./createSubscription"

const log = process.env.PRODUCTION === "true" ? () => void 0 : console.log

const handler = async (
  orderData,
  total,
  isSubscription,
  setOrderState,
  afterSuccess
) => {
  log("orderData", orderData)
  log("total", total)
  const nowStr = "" + now
  try {
    // get Token here
    const tokenResponse = await axios.post(IAMPORT_BASE_URL + "/getAccessToken")
    log("tokenResponse", tokenResponse)
    const access_token = tokenResponse.data.response.access_token
    const customer_uid = orderData.email + "_" + orderData.cardNumber.slice(-4)
    const merchant_uid =
      nowStr.substr(nowStr.length - 5) +
      " [" +
      orderData.type +
      "][" +
      (() => {
        let res = ""
        if (orderData.inflowFbPage) res += "post, "
        if (orderData.inflowFbAd) res += "fb-ad, "
        if (orderData.inflowInstaAd) res += "ins-ad, "
        if (orderData.inflowNewsletter) res += "news, "
        if (orderData.inflowKakao) res += "kakao, "
        if (orderData.inflowFriend) res += "fr, "
        if (orderData.inflowEtc) res += "etc, "
        return res.slice(0, -2)
      })() +
      "]"
    // make first Order here
    const orderResponse = await axios.post(IAMPORT_BASE_URL + "/makeOrder", {
      access_token,
      user_inputs: {
        buyer_name: orderData.name,
        buyer_email: orderData.email,
        buyer_tel: orderData.phone,
        customer_uid,
        merchant_uid,
        amount: total,
        card_number: orderData.cardNumber,
        expiry: orderData.expiry,
        birth: orderData.cardowner,
        pwd_2digit: orderData.cardpw,
        buyer_addr: orderData.buyerAddr,
      },
    })
    log("orderResponse(/makeOrder)", orderResponse)
    if (
      orderResponse.data.code === 0 &&
      orderResponse.data.response.failed_at === 0
    ) {
      if (isSubscription) {
        // add scheduled orders
        createSubscription(
          access_token,
          customer_uid,
          merchant_uid,
          orderData,
          total,
          setOrderState,
          subscriptionorderResponse => {
            alert("Hello, Framer X\n\n주문이 완료 되었습니다 감사합니다!")
            log("subscriptionorderResponse", subscriptionorderResponse)
            afterSuccess({
              schedule_at: subscriptionorderResponse.response[0].schedule_at,
              ...orderResponse.data.response,
            })
            setOrderState(5)
          }
        )
      } else {
        await sendMail(
          fromEmail,
          [noitifyEmail, orderData.email],
          orderData.name,
          orderData.type
        )
        alert("신청이 완료 되었습니다 감사합니다!")
        afterSuccess({
          schedule_at: "",
          manual: !isSubscription,
          ...orderResponse.data.response,
        })
        setOrderState(5)
      }
    } else {
      let err_msg = "에러(makeOrder) - " + orderResponse.data.message
      if (orderResponse.data.response != null) {
        log("orderResponse.data.response", orderResponse.data.response)
        err_msg = "결제에러 - " + orderResponse.data.response.fail_reason
      }
      alert(err_msg)
      setOrderState(0)
    }
  } catch (error) {
    log("error", error)
  }
}

export default handler
