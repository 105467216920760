import * as React from "react"
import { Provider } from "react-redux"
import store from "../../../store"
import { CheckoutForm } from "./CheckoutForm"

export function Checkout({ manual, installment, ...props }) {
  return (
    <Provider store={store}>
      <CheckoutForm manual={manual} installment={installment} />
    </Provider>
  )
}
