import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import "./layout.css"
import styled from "styled-components"
import * as System from "../../design-system"
import { breakPoint } from "../shared/consts"
import Image from "../components/image"
const Wrap = styled.div`
  margin: 0 auto;
  max-width: 1080px;
  padding-bottom: 30vh;
  /* overflow-x: hidden; */
  @media only screen and (max-width: ${breakPoint}px) {
    padding: 0px 24px 30vh;
  }
`
const Footer = styled.footer`
  margin-top: 100px;
  font-family: "NanumSquare";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
`
const SNSSection = styled.div`
  float: right;
`
const SNS = styled.a`
  width: 20px;
  display: inline-block;
  margin-left: 8px;
`
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <System.Header
        siteTitle={data.site.siteMetadata.title}
        onClick={() => navigate("/")}
      />
      <Wrap>
        <main>{children}</main>
        <Footer>
          © {new Date().getFullYear()} 하버 | 서울시 관악구 조원로 25 109 |
          +8210-3993-6177 | 사업자등록번호 : 559-63-00040 | 대표자 : 지용민
          <br />
          <br />
          통신판매업 신고번호 : 제 2019-서울강남-04995 호{/* sns links */}
          <SNSSection>
            {/* <SNS href="https://open.kakao.com/o/gppNyBhb" target="_blank">
              <Image filename="sns/kakao.png" />
            </SNS> */}
            {/* <SNS href="https://www.facebook.com/harbor.sch" target="_blank">
              <Image filename={"sns/fb.png"} />
            </SNS> */}
            {/* <SNS href="http://link.harbor.school/join" target="_blank">
              <Image filename={"sns/slack.png"} />
            </SNS> */}
            <SNS
              href="https://www.instagram.com/harbor.school/"
              target="_blank"
            >
              <Image filename={"sns/insta.png"} />
            </SNS>
            <SNS
              href="https://www.youtube.com/channel/UCPDOhghjUdCRv9a2Jkjwz_w?view_as=subscriber"
              target="_blank"
            >
              <Image filename={"sns/youtube.png"} />
            </SNS>
            <SNS href="https://medium.com/harbor-school" target="_blank">
              <Image filename={"sns/medi.png"} />
            </SNS>
          </SNSSection>
        </Footer>
      </Wrap>
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
