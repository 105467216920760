import * as React from "react"
import styled from "styled-components"
import * as System from "../../../design-system"

export function Heading1TwoLines({ text1, text2, ...props }) {
  return (
    <>
      <System.Spacing height={150} />
      <System.Heading1 {...props} h1={text1} />
      <System.Heading1 {...props} h1={text2} />
      <System.Spacing height={4} />
    </>
  )
}

Heading1TwoLines.defaultProps = {
  text1: "Type Text",
  text2: "Type Text",
}
