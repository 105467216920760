import * as React from "react"
import { Frame, useCycle, addPropertyControls, ControlType } from "framer"
import { Cover } from "./Cover"
import { CLASS_OPTIONS } from "../../../shared/classConsts/framer-korea-meetup"
// import { classOption } from '../../datas'
import useStore from "../../../datas/useStoreFkm"

export function ClassOption({ option, closed, alert, ...props }) {
  const [classOption, setClassOption] = useStore()

  return (
    <Frame
      // width="100%"
      // height="100%"
      width={335}
      height={50}
      background=""
      borderRadius={6}
      style={{
        cursor: "pointer",
        overflow: "hidden",
      }}
      onClick={
        !closed
          ? () =>
              setClassOption({
                selected: option,
              })
          : void 0
      }
    >
      <Cover
        name="Normal"
        // opacity={closed ? 0.3 : 1}
      >
        {CLASS_OPTIONS[option].long}
      </Cover>
      <Cover
        name="Selected"
        initial={{
          top: 50,
        }}
        animate={{
          top: classOption.selected == option ? 0 : 50,
        }}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
      >
        {CLASS_OPTIONS[option].long}
      </Cover>

      {closed && (
        <Cover name="Closed">이 옵션은 마감 되었습니다. 감사합니다 🙇</Cover>
      )}
      {alert && (
        <Cover
          name="Alert"
          initial={{
            top: -50,
          }}
          animate={{
            top: 50,
          }}
          transition={{
            loop: Infinity,
            ease: "linear",
            duration: 2,
          }}
        >
          마감 임박!
        </Cover>
      )}
    </Frame>
  )
}

ClassOption.defaultProps = {
  option: "workshopYes",
  closed: false,
  alert: false,
}

addPropertyControls(ClassOption, {
  option: { type: ControlType.String, title: "Option" },
  closed: { type: ControlType.Boolean, title: "Closed" },
  alert: { type: ControlType.Boolean, title: "Alert" },
})
