import axios from "axios"
import {
  IAMPORT_BASE_URL,
  SES_BASE_URL,
  noitifyEmail,
  fromEmail,
} from "../shared/consts"
import { now } from "../shared/classConsts/hello-framer-x"
import { NEXT_PAYMENT } from "../shared/classConsts/hello-framer-x"
import sendMail from "./sendMail"

const log = process.env.PRODUCTION === "true" ? () => void 0 : console.log
export const createSubscription = async (
  access_token,
  customer_uid,
  merchant_uid,
  orderData,
  total,
  setOrderState,
  afterSuccess
) => {
  log("orderData", orderData)
  log("total", total)
  const date = new Date()
  const schedule_at = NEXT_PAYMENT / 1000
  try {
    const response = await axios.post(IAMPORT_BASE_URL + "/paymentSchedule", {
      access_token,
      user_inputs: {
        customer_uid,
        schedules: [
          {
            merchant_uid: merchant_uid + "-scheduled-01", // 주문 번호
            schedule_at, // 결제 시도 시각 in Unix Time Stamp. ex. 다음 달 1일
            amount: total,
            name: "Hello, Framer X 2회차 결제",
            buyer_name: orderData.name,
            buyer_tel: orderData.phone,
            buyer_email: orderData.email,
          },
        ],
      },
    })
    log("response(/paymentSchedule)", response.data)
    if (
      response.data.code === 0 &&
      response.data.response[0].schedule_at > date.getTime() / 1000
    ) {
      await sendMail(
        fromEmail,
        [noitifyEmail, orderData.email],
        orderData.name,
        orderData.type
      )
      afterSuccess(response.data)
    } else {
      let err_msg = "에러(paymentSchedule) - " + response.data.message
      if (response.data.response != null) {
        log("response.data.response", response.data.response)
        err_msg = "결제에러 - " + response.data.response.fail_reason
      }
      alert(err_msg)
      setOrderState(0)
    }
  } catch (error) {
    log(error)
  }
}
