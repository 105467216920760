// import { getParameterByName } from "ruucm-util"
import { getParameterByName } from "../shared/utils"

import { createStore } from "./store"
import {
  CLASS_INITIAL_OPTION,
  // CURRENT_PERIOD,
  CURRENT_NUMBER_OF_THE_PRODUCTS,
  COUPON_B,
  COUPON_C,
  COUPON_D,
  COUPON_E,
  COUPON_F,
  COUPON_G,
} from "../shared/classConsts/hello-framer-x"

var total = CURRENT_NUMBER_OF_THE_PRODUCTS.price
var coupon = getParameterByName("coupon")
var manual = getParameterByName("manual")
console.log("coupon", coupon)
if (coupon == COUPON_B.key) total = COUPON_B.price
else if (coupon == COUPON_C.key) total = COUPON_C.price
else if (coupon == COUPON_D.key) total = COUPON_D.price
else if (coupon == COUPON_E.key) total = COUPON_E.price
else if (coupon == COUPON_F.key) total = COUPON_F.price
else if (coupon == COUPON_G.key) total = COUPON_G.price
else if (manual) total = Number(manual)
console.log("total", total)

const useStore = createStore({
  selected: CLASS_INITIAL_OPTION,
  total: total,
  showCouponNotification: false,
  couponDiscount: 0,
})

export default useStore
