import * as React from "react"
import styled from "styled-components"
import { motion, AnimateSharedLayout } from "framer-motion"

const Wrap = styled.div`
  position: fixed;
  right: 20px;
  top: 20px;
  display: grid;
  gap: 15px;
  z-index: 1;
  width: 460px;
  @media (max-width: 1080px) {
    width: 90%;
    right: 50%;
    transform: translateX(50%);
  }
`

export function NotificationPanel({ children }) {
  return <Wrap>{children}</Wrap>
}
