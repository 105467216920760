import * as React from "react"
import { Frame, useCycle } from "framer"
import styled, { css } from "styled-components"

const Wrap = styled(Frame)`
  font-size: 16px;
    font-family: "NanumSquare";
  font-weight: 400;

  ${props =>
    props.type == "Normal" &&
    css`
      background: white !important;
      border: 1px solid #263060;
    `}
  ${props =>
    props.type == "Selected" &&
    css`
      background: #2348a7 !important;
      color: white;
    `}
    ${props =>
      props.type == "Closed" &&
      css`
        background: #8c9ba5 !important;
        color: white;
        opacity: 0.9;
      `}
    ${props =>
      props.type == "Alert" &&
      css`
        background: #e9484d !important;
        color: white;
        font-family: "NanumSquare";
        font-family: "NanumSquare";
        font-weight: 800;
      `}
`

export function Cover({ children, name, ...props }) {
  return (
    <Wrap width="100%" height="100%" borderRadius={6} type={name} {...props}>
      {children}
    </Wrap>
  )
}
