import React from "react"
import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { Checkout } from "../Checkout"
import { ClassOption } from "../ClassOption"
import { TimeLeft } from "../TimeLeft"
import { CouponButton } from "../CouponButton"
import { Frame, Stack, transform } from "framer"
import useStore from "../../../datas/useStoreFkm"
import Countdown from "react-countdown-now"
import {
  CLASS_OPTIONS,
  CURRENT_PERIOD,
  CURRENT_NUMBER_OF_THE_PRODUCTS,
  deadLineFinal,
  INNER_SURPRISE_COUPON,
} from "../../../shared/classConsts/framer-korea-meetup"
import { preventScrollingBehindFixedOverlay } from "../../../shared/utils"

const Button = styled.div`
  background: #fdd388;
  cursor: pointer;
  text-align: center;
  height: 80px;
  padding: 10px 0;
  position: relative;
`
const Notice = styled(Frame)`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  color: #e9484d;
`
const BuyText = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 28px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  span {
    font-family: "NanumSquare";
    font-weight: 700;
    font-size: 18px;
    vertical-align: middle;
  }
`

const ClassOptions = styled(Frame)`
  width: 375px !important;
  height: 375px !important;
  border-radius: 6px;
`

const Wrap = styled(Stack)`
  width: 335px !important;
  height: 291px !important;
`

const Text1 = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  text-align: left;
`
const Text2 = styled.div`
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 16px;
  text-align: right;
`
const ClassTypeText = styled.div`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 18px;
  text-align: right;
`

const PurchaseSection = ({ manual, installment, scrollAmount, ...props }) => {
  const [classOption, setClassOption] = useStore()
  return (
    <Stack
      size="100%"
      background=""
      gap={0}
      top={100}
      style={{
        pointerEvents: "all",
      }}
    >
      {!manual && (
        <>
          <ClassOptions background="#fff">
            <Wrap center gap={10} background="">
              <Frame width="100%" height={21} background="">
                <Text1>Framer X 워크샵 참여여부 선택</Text1>
              </Frame>
              <Stack
                width={335}
                height={200}
                distribution="space-evenly"
                background=""
              >
                <ClassOption option="workshopYes" />
                <ClassOption option="workshopNo" />
                {/* <ClassOption option="weekendB" /> */}
              </Stack>

              <Frame width="100%" height={21} background="">
                <ClassTypeText
                  dangerouslySetInnerHTML={{
                    __html: CLASS_OPTIONS[classOption.selected].short,
                  }}
                >
                  {/* {CLASS_OPTIONS[classOption.selected].short} */}
                </ClassTypeText>
              </Frame>
              <Frame width="100%" height={19} background="">
                {/* <Text2>를 선택해 주셨습니다</Text2> */}
              </Frame>
            </Wrap>
          </ClassOptions>
          <Frame width={375} height={200} borderRadius={6}>
            <TimeLeft />
          </Frame>
          {/* INNER_SURPRISE_COUPON */}
          {INNER_SURPRISE_COUPON && (
            <Frame
              width={375}
              height={200}
              borderRadius={6}
              background=""
              opacity={transform(scrollAmount, [300, 600, 900], [0.1, 1, 0.1])}
              top={transform(scrollAmount, [300, 600], [200, 0])}
            >
              <CouponButton />
            </Frame>
          )}
        </>
      )}
      <Checkout manual={manual} installment={installment} />
    </Stack>
  )
}

export default PurchaseSection
