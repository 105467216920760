import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as System from "../../design-system"
import Image from "../components/image"
import StaticImage from "../components/static-image"
import {
  CURRENT_NUMBER_OF_THE_PRODUCTS,
  fromEmail,
} from "../shared/classConsts/hello-framer-x"
import { isClient, Guard } from "../shared/utils"

var PurchaseAreaFKM, useStore, classOption, setClassOption

const DetailLink = styled.a`
  color: black;
  text-decoration: none;
`

const Page = () => {
  const guard = Guard()
  if (guard && isClient) navigate("/")
  if (typeof window !== "undefined") {
    PurchaseAreaFKM = require("../components/PurchaseAreaFKM").default
    useStore = require("../datas/useStore").default
    ;[classOption, setClassOption] = useStore()
  }
  return (
    <Layout>
      <SEO
        title="Framer Korea Meetup"
        description="프레이머로 환승할 시간"
        metaImage={require("../images/fkm/Payment_pc.png")}
      />
      <div
        style={{
          paddingTop: 100,
        }}
      ></div>

      <Image filename="fkm/Payment_pc.png" />
      <br />
      <br />

      <System.PageTitle h1="Framer Korea Meetup" />
      <System.Heading1Default
        h1="프레이머로 환승할 시간"
        style={{
          margin: 0,
        }}
      />

      <br />

      <DetailLink href="https://framerkorea.org" target="_blank">
        <System.Heading2 h2="상세보기 〉" />
      </DetailLink>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {typeof window !== "undefined" && <PurchaseAreaFKM />}
    </Layout>
  )
}

export default Page
