import * as React from "react"
import styled from "styled-components"
import { breakPoint } from "../../src/shared/consts"
import "./fonts.css"

const H1 = styled.h1`
  font-family: "NanumSquare";
  font-weight: 700;
  font-size: 30px;
  line-height: 1.4;
  /* margin-top: 150px; */
  /* margin-bottom: 4px; */
  @media (max-width: ${breakPoint}px) {
    font-size: 24px;
  }
  /* background: transparant; */
`

export function Heading1({
  h1,
  size,
  mobileSize,
  tabletSize,
  desktopSize,
  breakPoint,
  margin,
  ...props
}) {
  return <H1 dangerouslySetInnerHTML={{ __html: h1 }} />
}

Heading1.defaultProps = {
  h1: "Type Text",
  breakPoint: 700,
  marginTop: 150,
  marginBottom: 4,
}
