import axios from "axios"
import {
  IAMPORT_SERVER_URL,
  IAMPORT_BASE_URL,
  SES_BASE_URL,
  noitifyEmail,
  fromEmail,
} from "../shared/consts"
import { now } from "../shared/classConsts/hello-framer-x"
import { NEXT_PAYMENT } from "../shared/classConsts/hello-framer-x"
import sendMail from "./sendMail"

const log = process.env.PRODUCTION === "true" ? () => void 0 : console.log

const handler = async (imp_uid, setOrderData) => {
  try {
    // get Token here
    const tokenResponse = await axios.post(IAMPORT_BASE_URL + "/getAccessToken")
    const { access_token } = tokenResponse.data.response // 인증 토큰
    // imp_uid로 아임포트 서버에서 결제 정보 조회
    const getPaymentData = await axios.post(
      IAMPORT_BASE_URL + "/checkPayments",
      {
        imp_uid: imp_uid,
        access_token: access_token,
      }
    )
    const paymentData = getPaymentData.data.response // 조회한 결제 정보
    setOrderData(paymentData)
    // fbq &&
    //   fbq("track", "Purchase", {
    //     value: paymentData.amount,
    //     currency: "KRW",
    //     content_name: "hello-framer-x",
    //   })
  } catch (error) {
    log("error", error)
    setOrderData("error")
  }
}

export default handler
