import { getParameterByName, commaPipe } from "ruucm-util"
import { isClient } from "../utils"
/**
 * Class Status
 */
export var hfOpened = false
var hfOpen = isClient && getParameterByName("hfOpen")
console.log("hfOpen", hfOpen)
if (hfOpen === "YOU") hfOpened = true

/**
 * Class Options
 */
export const CLASS_OPTIONS = {
  weekdayA: {
    short: "평일(화/목) 클래스",
    long: "화/목 x 4주 19:40 - 22:40 [10/6 ~ 10/29]",
    schedule: ["10/6, 10/8", "10/13, 10/15", "10/20, 10/22", "10/27, 10/29"],
  },
  weekendA: {
    short: "주말(토/일) 클래스A",
    long: "토/일 x 4주 13:00 - 16:00 [10/10 ~ 11/1]",
    schedule: ["10/10, 10/11", "10/17, 10/18", "10/24, 10/25", "10/31, 11/1"],
  },
  // weekendB: {
  //   short: "주말(토/일) 클래스B",
  //   long: "토/일 x 4주 17:00 - 20:00 [7/18 ~ 8/9]",
  //   schedule: ["7/18, 7/19", "7/25, 7/26", "8/1, 8/2", "8/8, 8/9"],
  // },
}
export const CLASS_INITIAL_OPTION = "weekdayA"

/**
 * Pricing Plan by Dates
 */

/**
 * TimeLeft
 */
export const now = Date.now()
export const deadLineSuperEarlyBird = new Date(
  "Sep 27 2020 23:59:00 UTC+09:00"
).getTime()
export const deadLineEarlyBird = new Date(
  "Oct 6 2020 02:00:00 UTC+09:00"
).getTime()
export const deadLineFinal = new Date("Oct 6 2020 02:00:00 UTC+09:00").getTime()

export const surpriceCouponDeadline = new Date(
  "Oct 6 2020 03:59:00 UTC+09:00"
).getTime()
export const framerCouponDeadline = new Date(
  "Oct 6 2020 03:59:00 UTC+09:00"
).getTime()

/**
 * Prices
 */
export const SUPER_EARLYBIRD_PRICE = 420000
export const SUPER_EARLYBIRD_TITLE = "슈퍼 얼리버드 특가"
export const SUPER_EARLYBIRD_DESCRIPTION = `${SUPER_EARLYBIRD_TITLE} / 월 ${commaPipe(
  SUPER_EARLYBIRD_PRICE / 6
)}원 <span>(6개월 할부 시)</span>`
export const SUPER_EARLYBIRD_LEFT_AMOUNT = 0
export const EARLYBIRD_PRICE = 450000
export const EARLYBIRD_TITLE = "얼리버드 특가"
export const EARLYBIRD_DESCRIPTION = `${EARLYBIRD_TITLE} / 월 ${commaPipe(
  EARLYBIRD_PRICE / 6
)}원 <span>(6개월 할부 시)</span>`
export const EARLYBIRD_LEFT_AMOUNT = 1
export const OPENSALE_PRICE = 480000
// export const OPENSALE_PRICE = 0
export const OPENSALE_TITLE = "온라인 오픈특가"
export const OPENSALE_DESCRIPTION = `${OPENSALE_TITLE} / 월 ${commaPipe(
  OPENSALE_PRICE / 6
)}원 <span>(6개월 할부 시)</span>`
export const OPENSALE_LEFT_AMOUNT = 6
export const ORIGINAL_PRICE = 580000
export const ORIGINAL_TITLE = "모집마감"
export const ORIGINAL_DESCRIPTION = ORIGINAL_TITLE
export const ORIGINAL_LEFT_AMOUNT = 6
export const COUPON_TITLE = "쿠폰할인"
export const COUPON_DESCRIPTION = COUPON_TITLE

export const SALE_PRICE_COUPON = 580000
export const SALE_PRICE_CHAT_COUPON = 580000

export const COUPON_B = {
  key: "PIER-9",
  price: 540,
}
export const COUPON_C = {
  key: "FKM-122",
  price: 540000,
}
export const COUPON_D = {
  key: "QUEEN-0118",
  price: 560000,
}
export const COUPON_E = {
  key: "THIS-9918",
  price: 682000,
}
export const COUPON_F = {
  key: "COU-620",
  price: 590000,
}
export const COUPON_G = {
  key: "WORK-499",
  price: 499000,
}
export const INNER_SURPRISE_COUPON = false

export var CURRENT_PERIOD = {
  description: OPENSALE_DESCRIPTION,
  price: ORIGINAL_PRICE, // Original Price,
}

if (deadLineSuperEarlyBird > now)
  CURRENT_PERIOD = {
    title: SUPER_EARLYBIRD_TITLE,
    description: SUPER_EARLYBIRD_DESCRIPTION,
    price: SUPER_EARLYBIRD_PRICE,
    leftAmount: SUPER_EARLYBIRD_LEFT_AMOUNT,
    deadLine: deadLineSuperEarlyBird,
  }
else if (deadLineEarlyBird > now)
  CURRENT_PERIOD = {
    title: EARLYBIRD_TITLE,
    description: EARLYBIRD_DESCRIPTION,
    price: EARLYBIRD_PRICE,
    leftAmount: EARLYBIRD_LEFT_AMOUNT,
    deadLine: deadLineEarlyBird,
  }
else if (deadLineFinal > now)
  CURRENT_PERIOD = {
    title: OPENSALE_TITLE,
    description: OPENSALE_DESCRIPTION,
    price: OPENSALE_PRICE,
    leftAmount: OPENSALE_LEFT_AMOUNT,
    deadLine: deadLineFinal,
  }
else if (now > deadLineFinal)
  CURRENT_PERIOD = {
    title: ORIGINAL_TITLE,
    description: ORIGINAL_DESCRIPTION,
    price: ORIGINAL_PRICE,
    leftAmount: ORIGINAL_LEFT_AMOUNT,
    deadLine: 0,
  }

var coupon = isClient && getParameterByName("coupon")
if (coupon == COUPON_B.key)
  CURRENT_PERIOD = {
    ...CURRENT_PERIOD,
    title: COUPON_TITLE,
    description: COUPON_DESCRIPTION,
    price: COUPON_B.price,
  }
else if (coupon == COUPON_C.key)
  CURRENT_PERIOD = {
    ...CURRENT_PERIOD,
    title: COUPON_TITLE,
    description: COUPON_DESCRIPTION,
    price: COUPON_C.price,
  }
else if (coupon == COUPON_D.key)
  CURRENT_PERIOD = {
    ...CURRENT_PERIOD,
    title: COUPON_TITLE,
    description: COUPON_DESCRIPTION,
    price: COUPON_D.price,
  }

/**
 * Pricing Plan by the Number of Products
 */
export const CURRENT_NUMBER_OF_THE_PRODUCTS = {
  description: hfOpened
    ? CURRENT_PERIOD.description
    : // + " (마지막 1자리)"
      "마감 되었습니다 감사합니다",
  number: 1,
  price: CURRENT_PERIOD.price, // Initial Price,
}

/**
 * Subscription
 */
export const NEXT_PAYMENT = new Date("Oct 25 2020 23:30:00 UTC+09:00").getTime() // 클래스 평일, 주말 모두 3주 끝난 당일 저녁 11시 30분

/**
 * 수강생 인터뷰 비디오
 */
export const videoIds = ["rYOUF1g3Mcs", "i42t2LeXLe8", "IJHM-sQYy5M"]
