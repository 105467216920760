import * as React from "react"
import styled from "styled-components"
import "./fonts.css"

const P = styled.p`
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  strong {
    color: rgb(233, 72, 77);
    font-family: "NanumSquare";
    font-weight: 700;
  }
  background: transparant;
  color: ${props => props.color};
  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
  }
`

export function Paragraph({
  text,
  mobileSize,
  tabletSize,
  desktopSize,
  breakPoint,
  color,
  ...props
}) {
  return <P dangerouslySetInnerHTML={{ __html: text }} color={color} />
}

Paragraph.defaultProps = {
  text: "Type Text",
  breakPoint: 700,
  color: "black",
}
