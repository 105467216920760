import * as React from "react"
import { useState } from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import * as System from "../../design-system"
import { breakPoint, colors } from "../../src/shared/consts"
import { videoIds } from "../../src/shared/classConsts/hello-framer-x"

const Wrap = styled.div`
  overflow: hidden;
`
const VideoWrap = styled.div`
  height: 450px;
  @media (max-width: ${breakPoint}px) {
    height: calc((100vw - 48px) * 0.5625);
  }
`

const ThumbnailWrap = styled.div`
  height: 450px;
`

const Thumbnail = styled(motion.div)`
  height: 33.3%;
  position: relative;
  @media (max-width: ${breakPoint}px) {
    height: 74%;
  }
  ${props =>
    props.closed &&
    css`
      pointer-events: none;
      ${ImageWrap} {
        background: #747474;
      }
    `}
`
const ImageWrap = styled.div`
  width: 250px;
  height: 100%;
  float: right;
  cursor: pointer;
  position: relative;
  @media (max-width: ${breakPoint}px) {
    width: 100%;
  }
`
const Image = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Overlay = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: rgba(35, 72, 167, 0.5);
  position: absolute;
  top: 0;
`
const OverlayText = styled.div`
  color: #ffffff;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 800;
  position: absolute;
  bottom: 5px;
  left: 7px;
  font-family: "NanumSquare";
`

const DesktopWrap = styled.div`
  @media (max-width: ${breakPoint}px) {
    display: none;
  }
`
const MobileWrap = styled.div`
  display: none;
  @media (max-width: ${breakPoint}px) {
    display: block;
  }
`

const MobileThumb0 = ({ currentVideo, setCurrentVideo }) => {
  return (
    <Thumbnail onClick={() => setCurrentVideo(0)}>
      <ImageWrap>
        <Image
          animate={{
            filter: currentVideo === 0 ? "grayscale(0%)" : "grayscale(100%)",
          }}
          src={"https://img.youtube.com/vi/" + videoIds[0] + "/hqdefault.jpg"}
        />
        <Overlay>
          <OverlayText>ep. 02 (이직에 도움)</OverlayText>
        </Overlay>
      </ImageWrap>
    </Thumbnail>
  )
}
const MobileThumb1 = ({ currentVideo, setCurrentVideo }) => {
  return (
    <Thumbnail onClick={() => setCurrentVideo(1)}>
      <ImageWrap>
        <Image
          animate={{
            filter: currentVideo === 1 ? "grayscale(0%)" : "grayscale(100%)",
          }}
          src={"https://img.youtube.com/vi/" + videoIds[1] + "/hqdefault.jpg"}
        />
        <Overlay>
          <OverlayText>ep. 01 (현직 디자이너)</OverlayText>
        </Overlay>
      </ImageWrap>
    </Thumbnail>
  )
}
const MobileThumb2 = ({ currentVideo, setCurrentVideo }) => {
  return (
    <Thumbnail onClick={() => setCurrentVideo(2)}>
      <ImageWrap>
        <Image
          animate={{
            filter: currentVideo === 2 ? "grayscale(0%)" : "grayscale(100%)",
          }}
          src={"https://img.youtube.com/vi/" + videoIds[2] + "/hqdefault.jpg"}
        />
        <Overlay>
          <OverlayText>ep. 03 (주니어 디자이너)</OverlayText>
        </Overlay>
      </ImageWrap>
    </Thumbnail>
  )
}

export function VideoPlaylist({ currentVideo, setCurrentVideo, ...props }) {
  return (
    <Wrap>
      <System.Row>
        <System.Column col={9}>
          <VideoWrap>
            <System.Video youtubeId={videoIds[currentVideo]} />
          </VideoWrap>
        </System.Column>

        <System.Column col={3}>
          <MobileWrap>
            <System.Row>
              <System.Column col={6} mCol={6}>
                {(() => {
                  switch (currentVideo) {
                    case 0:
                      return (
                        <MobileThumb1
                          currentVideo={currentVideo}
                          setCurrentVideo={setCurrentVideo}
                        />
                      )
                    case 1:
                      return (
                        <MobileThumb2
                          currentVideo={currentVideo}
                          setCurrentVideo={setCurrentVideo}
                        />
                      )
                    case 2:
                      return (
                        <MobileThumb0
                          currentVideo={currentVideo}
                          setCurrentVideo={setCurrentVideo}
                        />
                      )

                    default:
                      return "Add Valid currentVideo"
                  }
                })()}
              </System.Column>
              <System.Column col={6} mCol={6}>
                {(() => {
                  switch (currentVideo) {
                    case 0:
                      return (
                        <MobileThumb2
                          currentVideo={currentVideo}
                          setCurrentVideo={setCurrentVideo}
                        />
                      )
                    case 1:
                      return (
                        <MobileThumb0
                          currentVideo={currentVideo}
                          setCurrentVideo={setCurrentVideo}
                        />
                      )
                    case 2:
                      return (
                        <MobileThumb1
                          currentVideo={currentVideo}
                          setCurrentVideo={setCurrentVideo}
                        />
                      )

                    default:
                      return "Add Valid currentVideo"
                  }
                })()}
              </System.Column>
            </System.Row>
          </MobileWrap>

          <DesktopWrap>
            <ThumbnailWrap>
              <Thumbnail
                onClick={() => setCurrentVideo(0)}
                style={{
                  paddingBottom: 10,
                }}
                animate={{
                  x: currentVideo === 0 ? 0 : 20,
                }}
              >
                <ImageWrap>
                  <Image
                    animate={{
                      filter:
                        currentVideo === 0
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                    src={
                      "https://img.youtube.com/vi/" +
                      videoIds[0] +
                      "/hqdefault.jpg"
                    }
                  />
                  <Overlay
                    animate={{
                      opacity: currentVideo === 0 ? 0 : 1,
                    }}
                  >
                    <OverlayText>ep. 02 (이직에 도움)</OverlayText>
                  </Overlay>
                </ImageWrap>
              </Thumbnail>
              <Thumbnail
                onClick={() => setCurrentVideo(1)}
                style={{
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
                animate={{
                  x: currentVideo === 1 ? 0 : 20,
                }}
              >
                <ImageWrap>
                  <Image
                    animate={{
                      filter:
                        currentVideo === 1
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                    src={
                      "https://img.youtube.com/vi/" +
                      videoIds[1] +
                      "/hqdefault.jpg"
                    }
                  />
                  <Overlay
                    animate={{
                      opacity: currentVideo === 1 ? 0 : 1,
                    }}
                  >
                    <OverlayText>ep. 01 (현직 디자이너)</OverlayText>
                  </Overlay>
                </ImageWrap>
              </Thumbnail>
              <Thumbnail
                onClick={() => setCurrentVideo(2)}
                style={{
                  paddingTop: 10,
                }}
                animate={{
                  x: currentVideo === 2 ? 0 : 20,
                }}
              >
                <ImageWrap>
                  <Image
                    animate={{
                      filter:
                        currentVideo === 2
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                    src={
                      "https://img.youtube.com/vi/" +
                      videoIds[2] +
                      "/hqdefault.jpg"
                    }
                  />
                  <Overlay
                    animate={{
                      opacity: currentVideo === 2 ? 0 : 1,
                    }}
                  >
                    <OverlayText>ep. 03 (주니어 디자이너)</OverlayText>
                  </Overlay>
                </ImageWrap>
              </Thumbnail>
            </ThumbnailWrap>
          </DesktopWrap>
        </System.Column>
      </System.Row>
    </Wrap>
  )
}

VideoPlaylist.defaultProps = {}
