import * as React from "react"
import styled, { css } from "styled-components"
import Countdown from "react-countdown-now"

import {
  ORIGINAL_PRICE,
  EARLYBIRD_PRICE,
  SUPER_EARLYBIRD_PRICE,
  deadLineSuperEarlyBird,
  deadLineEarlyBird,
  deadLineFinal,
  now,
  CURRENT_PERIOD,
} from "../../shared/classConsts/framer-korea-meetup"

const Wrap = styled.div`
  text-align: center;
  background: #f5f7fa;
  height: 100%;
  width: 100%;
  border-radius: 6px;
`
const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`

const Label = styled.div`
  font-family: NanumSquareOTFEB, Helvetica;
  font-size: 18px;
  line-height: 1.25;
  margin-bottom: 20px;
`

const WarnEmoji = styled.span`
  font-size: 36px;
`

const CountdownWrap = styled.span`
  font-family: NanumSquareOTFL, Helvetica;
  line-height: 1.25;
  font-size: 36px;
  color: #e9484d;
`

const PriceVariation = () => {
  return (
    <span>
      가격이 인상됩니다!
      <br />(
      {CURRENT_PERIOD.description == "Framer Korea 밋업 기념 특가" &&
        SUPER_EARLYBIRD_PRICE.toString().slice(0, -4) + "만원 →"}
      {CURRENT_PERIOD.description == "얼리버드" &&
        EARLYBIRD_PRICE.toString().slice(0, -4) + "만원 → "}
      {ORIGINAL_PRICE.toString().slice(0, -4)}만원)
    </span>
  )
}

// component
export const TimeLeft = ({ ...props }) => {
  return (
    <Wrap>
      {CURRENT_PERIOD.description == "Framer Korea 밋업 기념 특가" ? (
        <Center>
          <Label>
            Framer Korea 밋업 기념 특가가 끝나면
            <br />
            <PriceVariation />
          </Label>
          <WarnEmoji>⏰ </WarnEmoji>
          <CountdownWrap>
            <Countdown date={deadLineSuperEarlyBird} />
          </CountdownWrap>
        </Center>
      ) : CURRENT_PERIOD.description == "얼리버드" ? (
        <Center>
          <Label>
            ️얼리버드가 끝나면
            <br />
            <PriceVariation />
          </Label>
          <WarnEmoji>⏰ </WarnEmoji>
          <CountdownWrap>
            <Countdown date={deadLineEarlyBird} />
          </CountdownWrap>
        </Center>
      ) : (
        <Center>
          <Label>모집마감 남은시간</Label>
          {CURRENT_PERIOD.description == "오픈특가" ? (
            <>
              <WarnEmoji>⏰ </WarnEmoji>
              <CountdownWrap>
                <Countdown date={deadLineFinal} />
              </CountdownWrap>
            </>
          ) : (
            "클래스가 마감 되었습니다 감사합니다."
          )}
        </Center>
      )}
    </Wrap>
  )
}
