import * as React from "react"
import axios from "axios"
import { SES_BASE_URL } from "../../shared/consts"
import { CLASS_OPTIONS } from "../../shared/classConsts/hello-framer-x"
import { log } from "../../shared/utils"
import template0 from "./html-emails/after-hf-checkout"
import template1 from "./html-emails/after-fkm-checkout"
import template2 from "./html-emails/after-webinar-checkout"

const handler = async (from, emails, name, productKey) => {
  const isFKM = productKey === "workshopYes" || productKey === "workshopNo"
  const subject =
    name +
    " 님" +
    (isFKM ? "이벤트 등록이 완료 되었습니다" : "클래스 등록이 완료되었습니다")

  const sesResponse = await axios.post(SES_BASE_URL + "/sendEmail", {
    emailFrom: from,
    emails: emails,
    subject: subject,
    // subject: "이벤트 등록이 완료 되었습니다",
    // htmlString: template2(),
    htmlString: isFKM
      ? template1(name, productKey)
      : template0(name, productKey),
  })
  log("sesResponse", sesResponse)
  return sesResponse
}

export default handler
