import * as React from "react"
import { useEffect } from "react"
import styled from "styled-components"
import { breakPoint, colors } from "../../src/shared/consts"
import { isMobile } from "../../src/shared/utils"
import { motion, useAnimation } from "framer-motion"
import "./fonts.css"

const Wrap = styled.div`
  position: relative;
`
const H1 = styled.h1`
  font-family: "NanumSquare";
  font-weight: 800;
  font-size: 58px;
  line-height: 1.2;
  /* margin-top: 80px; */
  /* margin-bottom: 4px; */
  @media (max-width: ${breakPoint}px) {
    font-size: 28px;
  }
  background: transparent;
`
const Bar = styled(motion.div)`
  width: 430px;
  height: 14px;
  position: absolute;
  bottom: 8px;
  background: ${colors.harborYellow};
  z-index: -1;
  @media (max-width: ${breakPoint}px) {
    width: 200px;
    height: 8px;
    bottom: 5px;
}
  }
`

export function PageTitle({
  h1,
  size,
  mobileSize,
  tabletSize,
  desktopSize,
  breakPoint,
  color,
  animating,
  currentVideo,
  ...props
}) {
  const barAnim = useAnimation()
  useEffect(() => {
    const makeAnimation = async () => {
      await barAnim.start({
        x: 0,
        opacity: 0,
        transition: {
          duration: 0,
        },
      })
      barAnim.start({
        x: isMobile ? 109 : 222,
        opacity: 1,
        transition: {
          delay: 0.28,
          duration: 0.28,
        },
      })
    }
    if (animating) makeAnimation()
  }, [currentVideo])
  return (
    <Wrap>
      <Bar
        style={{
          display: animating ? "initial" : "none",
        }}
        animate={barAnim}
      />
      <H1 dangerouslySetInnerHTML={{ __html: h1 }} />
    </Wrap>
  )
}

PageTitle.defaultProps = {
  h1: "Type Text",
  animating: false,
}
