import * as React from "react"
import styled from "styled-components"
import "./fonts.css"

const Wrap = styled.div`
  color: rgba(55, 53, 47, 0.6);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 2px;
  font-size: 14px;
  font-family: "NanumSquare";
  font-weight: 400;
`

export const Caption = ({ caption, ...props }) => (
  <Wrap dangerouslySetInnerHTML={{ __html: caption }}></Wrap>
)

Caption.defaultProps = {
  caption: "Image Caption",
}
