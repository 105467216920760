'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.FixedCreditCardInput = FixedCreditCardInput

var React = _interopRequireWildcard(require('react'))

var _reactCreditCardInput = _interopRequireDefault(
  require('react-credit-card-input')
)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj
  } else {
    var newObj = {}
    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc =
            Object.defineProperty && Object.getOwnPropertyDescriptor
              ? Object.getOwnPropertyDescriptor(obj, key)
              : {}
          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc)
          } else {
            newObj[key] = obj[key]
          }
        }
      }
    }
    newObj.default = obj
    return newObj
  }
}

function FixedCreditCardInput(props) {
  return React.createElement(_reactCreditCardInput.default, props)
}
