import * as React from "react"
import { isConstructionMode } from "./consts"

export const isClient = typeof window !== "undefined"
export const sleep = sec => {
  return new Promise(resolve => setTimeout(resolve, sec * 1000))
}

export const goPageTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0
}

export const isMobile = isClient && window.innerWidth <= 700

export const getDifference = (a, b) => {
  var i = 0
  var j = 0
  var result = ""

  while (j < b.length) {
    if (a[i] != b[j] || i == a.length) result += b[j]
    else i++
    j++
  }
  return result
}

export const preventScrollingBehindFixedOverlay = id => {
  var _overlay = document.getElementById(id)
  var _clientY = null // remember Y position on touch start

  _overlay.addEventListener(
    "touchstart",
    function(event) {
      if (event.targetTouches.length === 1) {
        // detect single touch
        _clientY = event.targetTouches[0].clientY
      }
    },
    false
  )

  _overlay.addEventListener(
    "touchmove",
    function(event) {
      if (event.targetTouches.length === 1) {
        // detect single touch
        disableRubberBand(event)
      }
    },
    false
  )

  function disableRubberBand(event) {
    var clientY = event.targetTouches[0].clientY - _clientY

    if (_overlay.scrollTop === 0 && clientY > 0) {
      // element is at the top of its scroll
      event.preventDefault()
    }

    if (isOverlayTotallyScrolled() && clientY < 0) {
      //element is at the top of its scroll
      event.preventDefault()
    }
  }

  function isOverlayTotallyScrolled() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
    return _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight
  }
}

export const log =
  process.env.PRODUCTION === "true" ? () => void 0 : console.log

export const getParameterByName = (name, url) => {
  if (!url) url = isClient ? window.location.href : ""
  name = name.replace(/[\[\]]/g, "\\$&")
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const correctPW = "122019"
export const localStorageSevice = (key, initialValue) => {
  let storedValue = null

  try {
    // Get from local storage by key
    const item = isClient && window.localStorage.getItem(key)
    // Parse stored json or if none return initialValue
    storedValue = item ? JSON.parse(item) : initialValue
  } catch (error) {
    // If error also return initialValue
    console.log(error)
    storedValue = initialValue
  }

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save to local storage
      isClient && window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return [storedValue, setValue]
}

export const Guard = () => {
  const [storedValue, setValue] = localStorageSevice("pw", "")
  const guard = isConstructionMode && storedValue !== correctPW
  if (guard && isClient) {
    const pw = window.prompt("Please enter the Password", "")
    if (pw) {
      setValue(pw)
      if (pw === correctPW) window.location.reload()
    }
  }
  return guard
}

export const handleNewline = text => {
  if (text)
    return text.split("\n").map((item, key) => (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    ))
}
