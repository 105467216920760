import * as React from "react"
import styled from "styled-components"
import * as System from "../../../design-system"
import { breakPoint, colors } from "../../../src/shared/consts"

const FullWidthHero = styled.div`
  background: ${colors.harborBlue};
  width: 100vw;
  margin-left: calc((-100vw + 1080px) / 2);
  @media (max-width: ${breakPoint}px) {
    width: 100vw;
    margin-left: -24px;
    padding: 0 20px;
  }
`
const Wrap = styled.div`
  margin: 0 auto;
  max-width: 1080px;
`

const Title = styled.div`
  font-family: "NanumSquare";
  font-weight: 900;
  font-size: 40px;
  line-height: 1.2;
  color: white;
  @media (max-width: ${breakPoint}px) {
    font-size: 28px;
  }
`
const SubTitle = styled.div`
  font-family: "NanumSquare";
  font-weight: 900;
  font-size: 30px;
  line-height: 1.2;
  color: white;
  @media (max-width: ${breakPoint}px) {
    font-size: 24px;
  }
`

export function HeroText({ titleText, subTitleText, children, ...props }) {
  return (
    <FullWidthHero>
      <Wrap>
        <System.Spacing height={110} />
        <Title>{titleText}</Title>
        <System.Spacing height={12} />
        <SubTitle dangerouslySetInnerHTML={{ __html: subTitleText }} />
        {children && (
          <>
            <System.Spacing height={20} />
            {children}
          </>
        )}
        <System.Spacing height={110} />
      </Wrap>
    </FullWidthHero>
  )
}

HeroText.defaultProps = {
  titleText: "Type Text",
  subTitleText: "Type Text",
}
