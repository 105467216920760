import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import * as System from "../../design-system"

const GatsbyImgWrap = styled.div``

const Image = ({ filename, caption, ...props }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename)
      })
      if (!image) {
        return null
      }
      return (
        <System.Image
          renderedImg={
            <GatsbyImgWrap>
              <Img alt={caption} fluid={image.node.childImageSharp.fluid} />
            </GatsbyImgWrap>
          }
        />
      )
    }}
  />
)

export default Image
