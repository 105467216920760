import * as React from "react"
import styled from "styled-components"
import * as System from "../../../design-system"

export function Heading1Default({ ...props }) {
  return (
    <>
      <System.Spacing height={150} />
      <System.Heading1 {...props} />
      <System.Spacing height={24} />
    </>
  )
}

Heading1Default.defaultProps = {
  h1: "Type Text",
}
