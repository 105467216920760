import { getParameterByName } from "./utils"
/**
 * App
 */
console.log("process.env.PRODUCTION", process.env.PRODUCTION)
export const isConstructionMode = false
export const breakPoint = 960 // based on bootstrap col-lg- class (https://getbootstrap.com/docs/4.0/layout/grid/)

/**
 * REST
 */
export const imp_uid = "imp63790808"
export const IAMPORT_SERVER_URL = "https://api.iamport.kr"
export const IAMPORT_BASE_URL =
  "https://ac6z0u6wpb.execute-api.ap-northeast-1.amazonaws.com/prod"
export const SES_BASE_URL =
  "https://dnfa18jeh9.execute-api.us-east-1.amazonaws.com/prod"
export const noitifyEmail = "ruucm@harbor.school"
export const fromEmail = "contact@harbor.school"

/**
 * Colors
 */
export const harborBlue = "#2348a7"
export const colors = {
  harborBlue: "#2348a7",
  harborYellow: "#FDD388",
  ui01: "#f8f8f8",
  support01: "#e9484d",
}
