import { getParameterByName } from "../utils"
/**
 * Class Status
 */
export const hfOpened = getParameterByName("pw") === "REBE" ? true : false
// export const hfOpened = false
/**
 * Class Options
 */
export const CLASS_OPTIONS = {
  workshopYes: {
    short: "당일 워크샵 참여를 원하실 경우 PC(맥, 윈도우)를 준비해 주세요",
    long: "참여 하겠습니다",
  },
  workshopNo: {
    short: "",
    long: "불참 하겠습니다",
  },
  // weekendB: {
  //   short: "주말(토/일) 클래스B",
  //   long: "토/일 x 4주 17:00 - 20:00 [1/18 ~ 2/9]",
  // },
}
export const CLASS_INITIAL_OPTION = "workshopYes"

/**
 * Pricing Plan by Dates
 */

/**
 * TimeLeft
 */
export const now = Date.now()
export const deadLineSuperEarlyBird = new Date(
  "Dec 2 2019 23:59:00 UTC+09:00"
).getTime()
export const deadLineEarlyBird = new Date(
  "Dec 8 2019 02:00:00 UTC+09:00"
).getTime()
export const deadLineFinal = new Date(
  "Jul 24 2020 23:59:00 UTC+09:00"
).getTime()

/**
 * Prices
 */
export const SUPER_EARLYBIRD_PRICE = 490000
export const EARLYBIRD_PRICE = 550000
export const OPENSALE_PRICE = 10000
// export const OPENSALE_PRICE = 700
export const ORIGINAL_PRICE = 720000

export const SALE_PRICE_COUPON = 620000
export const SALE_PRICE_CHAT_COUPON = 620000

export const COUPON_B = {
  key: "PIER-9",
  price: 151,
}
export const COUPON_C = {
  key: "FRC-122",
  price: 680000,
}
export const COUPON_D = {
  key: "DS-9918",
  price: 682000,
}
export const COUPON_E = {
  key: "THIS-9918",
  price: 682000,
}
export const COUPON_F = {
  key: "COU-620",
  price: 590000,
}
export const COUPON_G = {
  key: "WORK-499",
  price: 499000,
}
export const INNER_SURPRISE_COUPON = false

export var CURRENT_PERIOD = {
  description: "오픈특가",
  price: ORIGINAL_PRICE, // Original Price,
}

if (deadLineSuperEarlyBird > now)
  CURRENT_PERIOD = {
    description: "Framer Korea 밋업 기념 특가",
    price: SUPER_EARLYBIRD_PRICE,
  }
else if (deadLineEarlyBird > now)
  CURRENT_PERIOD = {
    description: "얼리버드",
    price: EARLYBIRD_PRICE,
  }
else if (now > deadLineFinal)
  CURRENT_PERIOD = {
    description: "모집마감",
    price: 0,
  }

/**
 * Pricing Plan by the Number of Products
 */
export const CURRENT_NUMBER_OF_THE_PRODUCTS = {
  description: hfOpened ? "₩10,000 (1매)" : "마감 되었습니다 감사합니다", // - 현재 40자리 남음
  number: 40,
  price: OPENSALE_PRICE, // Initial Price,
}

/**
 * Subscription
 */
export const NEXT_PAYMENT = new Date("Feb 7 2020 22:59:00 UTC+09:00").getTime() // 클래스 평일, 주말 모두 3주 끝난 당일 저녁 10시
