/**
 *
 * OrderComplete
 *
 */

import * as React from "react"
import styled, { css } from "styled-components"
import { commaPipe } from "ruucm-util"

const Wrap = styled.div`
  /* padding-top: 900px; */
  padding-bottom: 100px;
  padding-left: 20px;
`
const Title = styled.div`
  font-family: "NanumSquare";
  font-family: "NanumSquare";
  font-weight: 800;
  font-size: 18px;
  color: #2348a7;
  strong {
    color: #e9484d;
  }
`
const Info = styled.div`
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 16px;
  color: #2348a7;
  margin-top: 30px;
  line-height: 30px;
  strong {
    color: #e9484d;
  }
`

const getScheduledData = unix_timestamp => {
  var date = new Date(unix_timestamp * 1000)
  console.log("nextDate", date)
  var krMonths = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ]

  // Will display time in 10:30:23 format
  var formattedTime = krMonths[date.getMonth()] + "/" + date.getDate() + "일"

  return formattedTime
}

export const InstallmentOrderComplete = ({
  // from parent
  orderInfo,
  ...props
}) => {
  return orderInfo ? (
    <Wrap>
      {!orderInfo.manual ? (
        <div>
          <Title>
            주문이 성공적으로 접수 되었습니다
            <br />
            {/* <strong>(총 2회차 수업입니다)</strong> */}
          </Title>
          <Info>
            주문내역 : {commaPipe(orderInfo.amount)} 원 x 3개월 분할 결제 /
            <br />
            {/*(<strong>{getScheduledData(orderInfo.schedule_at)}</strong>,
            2회차 중급반 분 자동 결제) */}
            <br />
            주문번호 : {orderInfo.apply_num}
            <br />
            <br />
            {/* *자동 결제분은
            <br />
            [홈페이지 - Hello, Framer X - 신청내역] 에서
            <br />
            조회 & 취소하실 수 있습니다 */}
          </Info>
        </div>
      ) : (
        <div>
          <Title>
            주문이 성공적으로 접수 되었습니다
            <br />
          </Title>
          <Info>
            주문내역 : {commaPipe(orderInfo.amount)} 원
            <br />
            주문번호 : {orderInfo.apply_num}
            <br />
          </Info>
        </div>
      )}
    </Wrap>
  ) : (
    <div />
  )
}
