import * as React from "react"
import styled, { css } from "styled-components"

const Wrap = styled.div`
  background: ${props => (props.show ? "skyblue" : "")};
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  @media (max-width: 1080px) {
    height: ${props => props.mobileHeight}px;
  }
  ${props =>
    props.type === "h" &&
    css`
      display: inline-block;
      height: initial;
    `}
  ${props =>
    props.show === false &&
    css`
      opacity: 0;
    `}
`

export function Spacing({ ...props }) {
  return <Wrap {...props} />
}

Spacing.defaultProps = {
  height: 30,
  width: 30,
  type: "v", // h(horizontal), v(vertical)
  show: false,
}
