import * as React from "react"
import * as System from "../../../design-system"

export function Heading2TwoLines({ text1, text2, ...props }) {
  return (
    <>
      <System.Spacing height={80} />
      <System.Heading2 {...props} h2={text1} />
      <System.Heading2 {...props} h2={text2} />
      <System.Spacing height={1} />
    </>
  )
}

Heading2TwoLines.defaultProps = {
  text1: "Type Text",
  text2: "Type Text",
}
