import React from "react"
import styled from "styled-components"
import * as System from "../../design-system"

const Wrap = styled.div`
  margin: 8px 0;
`
const Img = styled.img`
  width: 100%;
`

const StaticImage = ({ caption, source, ...props }) => (
  <Wrap>
    <Img src={source} alt={caption} />
    <System.Caption caption={caption} />
  </Wrap>
)

export default StaticImage
